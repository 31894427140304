import React from 'react';

import AnimationRevealPage from 'Migr8/components/helpers/AnimationRevealPage.js';
import Hero from 'components/hero/BackgroundAsImageWithCenteredContent.js';
import DownloadApp from 'components/cta/DownloadApp.js';
import FAQ from 'components/faqs/SimpleWithSideImage.js';
function Viewform(props) {
    return (
        <AnimationRevealPage disabled>
            <Hero />
            <FAQ />
            <DownloadApp />
        </AnimationRevealPage>
    );
}

export default Viewform;
