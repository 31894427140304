import React from 'react';
import AnimationRevealPage from 'Migr8/components/helpers/AnimationRevealPage.js';
import Footer from 'Migr8/components/footer/FiveColumnDark.js';
import Hero from 'Migr8/components/hero/TwoColumnWithPrimaryBackground.js';
import Features from 'Migr8/components/features/mainSection1';
import Capture from 'Migr8/components/features/mainSection2';
import Azure from 'Migr8/components/features/mainsection3';
import Products from 'Migr8/components/blogs/GridWithFeaturedPost';
import CTA from './../components/cta/GetStarted';
import Examples from './components/features/TwoColumnWithImageAndProfilePictureReview';
import DT from './components/features/TwoColWithTwoFeaturesAndButtons';
import tw from 'twin.macro';
import { ContentWithVerticalPadding } from 'components/misc/Layouts';
const SectionContainer = tw(ContentWithVerticalPadding)``;
const FrontPage = () => {
    return (
        <AnimationRevealPage disabled>
            <Hero />

            <DT />
            <SectionContainer id="azure">
                <Azure />
            </SectionContainer>
            <Examples />

            <Products />

            <CTA />

            <Footer />
        </AnimationRevealPage>
    );
};

export default FrontPage;
