import React from 'react';
import AnimationRevealPage from 'Migr8/components/helpers/AnimationRevealPage.js';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import tw from 'twin.macro';
import styled from 'styled-components'; //eslint-disable-line
import { css } from 'styled-components/macro'; //eslint-disable-line
import Header from 'Migr8/components/headers/light.js';
import Footer from 'Migr8/components/footer/FiveColumnDark.js';
import MainFeature1 from 'Migr8/components/features/TwoColWithButton.js';
import { ContentWithVerticalPadding } from 'components/misc/Layouts';
import background from 'images/sec.jpg';
import {
    SectionHeading,
    Subheading as SubheadingBase,
} from 'components/misc/Headings.js';
import MainFeature2 from 'components/features/TwoColSingleFeatureWithStats2.js';
import MainFeature3 from 'components/features/TwoColWithTwoHorizontalFeaturesAndButton';
import Features from 'components/features/ThreeColSimple.js';

import SupportIconImage from 'images/support-icon.svg';
import ShieldIconImage from 'images/shield-icon.svg';
import CustomerLoveIconImage from 'images/simple-icon.svg';

import { ReactComponent as BriefcaseIcon } from 'feather-icons/dist/icons/copy.svg';
import { ReactComponent as MoneyIcon } from 'feather-icons/dist/icons/columns.svg';
import ReactModalAdapter from '../helpers/ReactModalAdapter.js';
import ResponsiveVideoEmbed from './components/helpers/ResponsiveVideoEmbed.js';

import { ReactComponent as PlayIcon } from 'feather-icons/dist/icons/play-circle.svg';
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-1.svg';
import { ReactComponent as SvgDecoratorBlob2 } from 'images/dot-pattern.svg';
import DesignIllustration from './assets/images/secu.png';
import Viewform from './assets/images/temp.png';
import Acid from './assets/images/temp.png';
import ShieldIcon from 'feather-icons/dist/icons/shield.svg';
import EyeOffIcon from 'feather-icons/dist/icons/package.svg';
import LayersIcon from 'feather-icons/dist/icons/layers.svg';
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;
const Heading = tw(SectionHeading)`w-full text-red-700`;
const VerticalSpacer = tw.div`mt-20 w-full`;
const Subheading = tw.span`uppercase tracking-wider text-sm text-primary-500`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-400 max-w-lg mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const ActionButton = tw(
    AnchorLink
)`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const PrimaryButton = tw(ActionButton)``;
//const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const WatchVideoButton = styled.button`
    ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-gray-400 transition duration-300 hocus:text-red-700 focus:outline-none`}
    .playIcon {
        ${tw`stroke-1 w-12 h-12`}
    }
    .playText {
        ${tw`ml-2 font-medium`}
    }
`;
const SectionContainer = tw(ContentWithVerticalPadding)``;
const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
    ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
    ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const StyledModal = styled(ReactModalAdapter)`
    &.mainHeroModal__overlay {
        ${tw`fixed inset-0 z-50`}
    }
    &.mainHeroModal__content {
        ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
    }
    .content {
        ${tw`w-full lg:p-16`}
    }
`;

const Container = styled.div`
    ${tw`relative -mx-8 -mt-8 pt-8 px-8 bg-center bg-gray-900 bg-cover h-screen min-h-144`}
    background-image: url("${background}");
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;
const myfet = [
    {
        Icon: BriefcaseIcon,
        title: 'Point Cloud Registration',
        description:
            'Blundergat uses a state-of-the-art hardware accelerated Particle-Swarm-Optimization algorithm, to rapidly register/align even the largest of point clouds. ',
        iconContainerCss: tw`bg-teal-300 text-teal-800`,
    },
    {
        Icon: MoneyIcon,
        title: 'Mesh Creation and Decimation',
        description:
            'Blundergat also provides automated meshing and decimation, again using the very latest techniques and algorithms to ensure we are providing clients with the best possible experience.',
        iconContainerCss: tw`bg-red-300 text-red-800`,
    },
];
export default ({
    heading = 'Security',
    description = 'At Migr8 we understand the critical nature of security and the importance of allowing clients to have complete control and ownership of their own data. Our software stack is secure, it’s flexible, scalable and here to help streamline your business. Partnered with Microsoft Azure, we adopt the very latest methods to ensure enterprise-grade security for your data. ',
    primaryButtonText = 'Learn more',
    primaryButtonUrl = '#landingPageDemos',
    watchVideoButtonText = 'Watch Video',
    watchVideoYoutubeUrl = 'https://www.youtube.com/embed/_GuOjXYl5ew',
    imageSrc = DesignIllustration,
    imageCss = null,
    imageDecoratorBlob = false,
}) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);

    const toggleModal = () => setModalIsOpen(!modalIsOpen);
    return (
        <AnimationRevealPage disabled>
            <Container>
                <Header light={true} />
                <TwoColumn>
                    <LeftColumn>
                        <Heading>{heading}</Heading>
                        <Paragraph>{description}</Paragraph>
                        <Actions>
                            <PrimaryButton as="a" href={primaryButtonUrl}>
                                {primaryButtonText}
                            </PrimaryButton>
                            {
                                !(
                                    <WatchVideoButton onClick={toggleModal}>
                                        <span className="playIconContainer">
                                            <PlayIcon className="playIcon" />
                                        </span>
                                        <span className="playText">
                                            {watchVideoButtonText}
                                        </span>
                                    </WatchVideoButton>
                                )
                            }
                        </Actions>
                    </LeftColumn>
                    <RightColumn>
                        <IllustrationContainer>
                            <img css={imageCss} src={imageSrc} alt="Hero" />
                            {imageDecoratorBlob && <DecoratorBlob2 />}
                        </IllustrationContainer>
                    </RightColumn>
                </TwoColumn>
                <DecoratorBlob1 />
                <StyledModal
                    closeTimeoutMS={300}
                    className="mainHeroModal"
                    isOpen={modalIsOpen}
                    onRequestClose={toggleModal}
                    shouldCloseOnOverlayClick={true}
                >
                    <CloseModalButton onClick={toggleModal}>
                        <CloseIcon tw="w-6 h-6" />
                    </CloseModalButton>
                    <div className="content">
                        <ResponsiveVideoEmbed
                            url={watchVideoYoutubeUrl}
                            tw="w-full"
                        />
                    </div>
                </StyledModal>
            </Container>
            <SectionContainer id="landingPageDemos">
                <MainFeature1
                    bg="#f7fafc"
                    subheading={<Subheading>Privacy and Security</Subheading>}
                    heading="Privacy and Security"
                    buttonRounded={false}
                    primaryButtonUrl={'./viewform'}
                    description="At Migr8, we are committed to the security of your data. Enterprise-grade authentication is baked into all of our core services and our policies, tools and procedures are built to protect your data and meet your privacy obligations, no matter how stringent. 

                    All of the data we handle, either at rest or in transit, is encrypted and we do not request or store any personal information.
                    
                    "
                    primaryButtonText="Read more"
                    imageSrc={ShieldIcon}
                    textOnLeft={false}
                />
            </SectionContainer>
            <MainFeature1
                bg="#edf2f7"
                subheading={
                    <Subheading>Data Residency and Resilience</Subheading>
                }
                heading="Flexible Data Management "
                buttonRounded={false}
                description="At Migr8 we offer flexibility to our clients. Whilst you can be sure that any data kept on our servers is stored with the highest security protocols in-place, our service architecture has been designed to allow complete flexibility when it comes to data storage. As such, there are three types of deployments we can offer and these can be chosen and tailored to meet client  requirements…"
                primaryButtonText="Read more"
                primaryButtonUrl={'./scannerApp'}
                imageSrc={EyeOffIcon}
            />
            <SectionContainer>
                <MainFeature1
                    bg="#f7fafc"
                    subheading={<Subheading>Microsoft partnered</Subheading>}
                    heading="Azure intergration"
                    buttonRounded={false}
                    primaryButtonUrl={'./viewform'}
                    description="Partnered with Microsoft Azure, we adopt the very latest methods to ensure enterprise-grade security for your data. 
                    "
                    primaryButtonText="Read more"
                    imageSrc={LayersIcon}
                    textOnLeft={false}
                />
            </SectionContainer>
            <Footer />
        </AnimationRevealPage>
    );
};
