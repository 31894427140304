import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import {
    SectionHeading,
    Subheading as SubheadingBase,
} from 'components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import { ReactComponent as BriefcaseIcon } from 'feather-icons/dist/icons/camera.svg';
import { ReactComponent as MoneyIcon } from 'feather-icons/dist/icons/map-pin.svg';
import { ReactComponent as IntIcon } from 'feather-icons/dist/icons/link.svg';
import { ReactComponent as DataIcon } from 'feather-icons/dist/icons/hard-drive.svg';
import TeamIllustrationSrc from 'images/capture.jpeg';

const Container = tw.div`relative bg-gray-100 -mb-8 -mx-8 px-8 py-0 lg:py-0`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft
        ? tw`md:mr-12 lg:mr-16 md:order-first`
        : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(
    SubheadingBase
)`text-center text-primary-500 md:text-left`;
const Heading = tw(
    SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mt-8 max-w-lg mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
    ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
    svg {
        ${tw`w-5 h-5 text-primary-500`}
    }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-primary-500`;
const FeatureDescription = tw.div`mt-1 text-sm`;

const PrimaryButton = tw(
    PrimaryButtonBase
)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

export default ({
    subheading = 'Asset Digital Twins',
    heading = (
        <>
            Real-time data feeds coupled to a{' '}
            <span tw="text-primary-500">photorealistic</span> 3D model.
        </>
    ),
    description = 'Asset Digital Twins created through Migr8 can combine with the Internet of Things (IoT) sensors to provide real-time telemetry and data feeds coupled to a photorealistic 3D model, and will integrate seamlessly with all digital twin platform providers to provide huge real world application value. Migr8 breaks down the barriers between data technologists who create and configure data digital twins and the everyday users of those assets and buildings and environments.',
    primaryButtonText = 'See Our Portfolio',
    primaryButtonUrl = 'https://timerse.com',
    features = null,
    textOnLeft = true,
}) => {
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

    /*
     * Change the features variable as you like, add or delete objects
     * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
     */
    const defaultFeatures = [
        {
            Icon: BriefcaseIcon,
            title: 'Capture',
            description:
                'Capture both indoor and outdoor spaces instantly, easily and cost effectively, without the need for specialist training, using either 360 photography or LIDAR scanners. Generate millimetre accurate 3D models and point clouds in real time.',
        },
        {
            Icon: MoneyIcon,
            title: 'Assetize',
            description:
                'Tag assets at the point of tour creation, and instantly navigate and inspect your spaces from anywhere in the world on any device. Zero delay from scan to remote navigation of your digital twin.',
        },
        {
            Icon: IntIcon,
            title: 'Integrate',
            description:
                'Deployment on Microsoft Azure as well as all leading digital twin infrastructure platforms is straightforward and highly secure.',
        },
        {
            Icon: DataIcon,
            title: 'Own your Data',
            description:
                'Designed with defence sector security requirements, all the data produced is owned, controlled and hosted locally by our clients on their own secure servers.',
        },
    ];

    if (!features) features = defaultFeatures;

    return (
        <Container>
            <TwoColumn>
                <ImageColumn>
                    <Image imageSrc={TeamIllustrationSrc} />
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        <Subheading>{subheading}</Subheading>
                        <Heading>{heading}</Heading>
                        <Description>{description}</Description>
                        <Features>
                            {features.map((feature, index) => (
                                <Feature key={index}>
                                    <FeatureIconContainer>
                                        {<feature.Icon />}
                                    </FeatureIconContainer>
                                    <FeatureText>
                                        <FeatureHeading>
                                            {feature.title}
                                        </FeatureHeading>
                                        <FeatureDescription>
                                            {feature.description}
                                        </FeatureDescription>
                                    </FeatureText>
                                </Feature>
                            ))}
                        </Features>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
};
