import 'tailwindcss/dist/base.css';
import 'styles/globalStyles.css';
import React from 'react';

import ProductsPage from 'Migr8/products.js';
import TechnologiesPage from 'Migr8/Technologies.js';
import SecurityPage from 'Migr8/Security.js';
import ContactUsPage from 'Migr8/ContactUs.js';
import TermsOfService from 'pages/TermsOfService';
import MainLandingPage from 'Migr8/frontPage';
import ViewformPage from 'pages/subpage/viewform';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

export default function App() {
    // If you want to disable the animation just use the disabled `prop` like below on your page's component
    // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

    return (
        <Router>
            <Switch>
                <Route path="/products">
                    <ProductsPage />
                </Route>
                <Route path="/viewform">
                    <ViewformPage />
                </Route>
                <Route path="/technologies">
                    <TechnologiesPage />
                </Route>
                <Route path="/security">
                    <SecurityPage />
                </Route>
                <Route path="/contactus">
                    <ContactUsPage />
                </Route>
                <Route path="/terms">
                    <TermsOfService />
                </Route>
                <Route path="/privacy">
                    <PrivacyPolicy />
                </Route>
                <Route path="/disclaimer">
                    <TermsOfService />
                </Route>
                <Route path="/gdpr">
                    <TermsOfService />
                </Route>
                <Route path="/">
                    <MainLandingPage />
                </Route>
            </Switch>
        </Router>
    );
}
