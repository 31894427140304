import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line

import LogoImage from './../../assets/images/migr8Logo.svg';
import { ReactComponent as FacebookIcon } from 'images/facebook-icon.svg';
import { ReactComponent as TwitterIcon } from 'images/twitter-icon.svg';
import { ReactComponent as YoutubeIcon } from 'images/youtube-icon.svg';

const Container = tw.div`relative bg-gray-900 text-gray-100 -mx-8 -mb-8 px-8 `;
const Content = tw.div`max-w-screen-xl mx-auto pt-16  pb-8`;
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;
const Linkd = tw.a`text-red-600 border-b-2 border-transparent hocus:text-red-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black`;

const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
    ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
    svg {
        ${tw`w-4 h-4`}
    }
`;
const SocialLinkd = styled.a`
    ${tw`cursor-pointer inline-block p-2 rounded-full bg-red-900 text-red-600 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
    svg {
        ${tw`w-4 h-4`}
    }
`;
const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`;
const CopyrightNotice = tw.div``;
const CompanyInfo = tw.div``;

const Divider = tw.div`my-8 border-b-2 border-gray-800`;
export default () => {
    return (
        <Container>
            <Content>
                <FiveColumns>
                    <CompanyColumn>
                        <LogoContainer>
                            <LogoImg src={LogoImage} />
                            <LogoText>Migr8 Ltd.</LogoText>
                        </LogoContainer>
                        <CompanyAddress>
                            1 Rosoman Place, EC1R 0JY, London
                        </CompanyAddress>
                        {
                            !(
                                <SocialLinksContainer>
                                    <SocialLinkd href="https://facebook.com">
                                        <FacebookIcon />
                                    </SocialLinkd>
                                    <SocialLinkd href="https://twitter.com">
                                        <TwitterIcon />
                                    </SocialLinkd>
                                    <SocialLinkd href="https://youtube.com">
                                        <YoutubeIcon />
                                    </SocialLinkd>
                                </SocialLinksContainer>
                            )
                        }
                    </CompanyColumn>
                    <Column>
                        <ColumnHeading>Quick Links</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <Link href="/products">Products</Link>
                            </LinkListItem>

                            <LinkListItem>
                                <Link href="/technology">Technology</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="/security">Security</Link>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                    <Column>
                        <ColumnHeading>Products</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <Link href="/products#viewform">Viewform</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="/products#capture">Capture</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="/products#temple">Temple</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="/technologies">Technologies</Link>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                    <Column>
                        <ColumnHeading>Legal</ColumnHeading>
                        <LinkList>
                            {
                                !(
                                    <LinkListItem>
                                        <Linkd href="/gdpr">GDPR</Linkd>
                                    </LinkListItem>
                                )
                            }
                            <LinkListItem>
                                <Link href="/privacy">Privacy Policy</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="/terms">Terms of Service</Link>
                            </LinkListItem>
                            {
                                !(
                                    <LinkListItem>
                                        <Linkd href="/disclaimer">
                                            Disclaimer
                                        </Linkd>
                                    </LinkListItem>
                                )
                            }
                        </LinkList>
                    </Column>
                    <Column>
                        <ColumnHeading>Contact</ColumnHeading>
                        <LinkList>
                            <LinkListItem>+44 203 588 2028</LinkListItem>
                            <LinkListItem>
                                <Link href="mailto:contact@migr8.com">
                                    contact@migr8.com
                                </Link>
                            </LinkListItem>

                            <LinkListItem>
                                <Link href="/contactus">Report Abuse</Link>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                </FiveColumns>
                <Divider />
                <CopyrightAndCompanyInfoRow>
                    <CopyrightNotice>
                        &copy; 2022 Migr8 Ltd. All Rights Reserved.
                    </CopyrightNotice>
                    <CompanyInfo>Technologies that aid.</CompanyInfo>
                </CopyrightAndCompanyInfoRow>
            </Content>
        </Container>
    );
};
