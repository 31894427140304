import { useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import {
    SectionHeading,
    Subheading as SubheadingBase,
} from 'components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import EmailIllustrationSrc from './../../assets/images/email.png';
import EmailIllustrationSrc2 from './../../assets/images/manage.jpg';
import emailjs from '@emailjs/browser';
const Container = tw.div`relative  `;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft
        ? tw`md:mr-12 lg:mr-16 md:order-first`
        : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(
    SubheadingBase
)`text-center text-primary-500 md:text-left`;
const Heading = tw(
    SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: 'textarea' })`
    ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;
const Button = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
    messText = '',
    subheading = 'Contact Us',
    heading = (
        <>
            Let’s Start a <span tw="text-primary-500">Conversation.</span>
        </>
    ),
    heading2 = <>Sending...</>,
    description = 'Please get in touch with the form below.',
    submitButtonText = 'Send',
    formAction = '',
    formMethod = 'post',
    textOnLeft = true,
}) => {
    const [contactInfo, setContactInfo] = useState({
        email: '',
        fullname: '',
        subject: '',
        message: '',
    });
    const [text, setText] = useState(heading);
    const [mess, setMess] = useState(false);
    const handleChange = (event) => {
        setContactInfo({
            ...contactInfo,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setText(heading2);
        // console.log(contactInfo);
        setContactInfo({ email: '', fullname: '', subject: '', message: '' });
        send();
    };
    const send = () => {
        emailjs
            .send(
                'service_h1dawnt',
                'template_78wctqq',
                contactInfo,
                'oNLjcEnyW0dEq7O3z'
            )
            .then(
                (response) => {
                    setText('Sent! Thank you.');
                    setMess(true);
                    console.log('SUCCESS!', response.status, response.text);
                },
                (err) => {
                    setText('Something went wrong. Hm...');
                    console.log('FAILED...', err);
                }
            );
    };
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

    return (
        <Container>
            <TwoColumn>
                <ImageColumn>
                    {mess ? (
                        <Image imageSrc={EmailIllustrationSrc2} />
                    ) : (
                        <Image imageSrc={EmailIllustrationSrc} />
                    )}
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        {subheading && <Subheading>{subheading}</Subheading>}
                        <Heading>{text}</Heading>
                        {description && (
                            <Description>{description}</Description>
                        )}
                        <Form onSubmit={handleSubmit}>
                            <Input
                                onChange={handleChange}
                                type="email"
                                name="email"
                                placeholder="Your Email Address"
                                value={contactInfo.email}
                            />
                            <Input
                                onChange={handleChange}
                                type="text"
                                name="fullname"
                                placeholder="Full Name"
                                value={contactInfo.fullname}
                            />
                            <Input
                                onChange={handleChange}
                                type="text"
                                name="subject"
                                placeholder="Subject"
                                value={contactInfo.subject}
                            />
                            <Textarea
                                onChange={handleChange}
                                name="message"
                                placeholder="Your Message Here"
                                value={contactInfo.message}
                            />
                            <Button>{submitButtonText}</Button>
                        </Form>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
};
