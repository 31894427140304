import React from 'react';
import {
    Container as ContainerBase,
    ContentWithPaddingXl,
} from 'components/misc/Layouts';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import {
    SectionHeading,
    Subheading as SubheadingBase,
} from 'components/misc/Headings';
import { SectionDescription } from 'components/misc/Typography';
import { ReactComponent as SvgDotPatternIcon } from 'images/dot-pattern.svg';
import Poster from './../../assets/images/tom.jpg';
import vPoster from './../../assets/images/viewformProd.jpg';
import mlPoster from './../../assets/images/Vathos.jpg';
import pcaPoster from './../../assets/images/blunder3.png';
import fdPoster from './../../assets/images/Aether.jpg';
const HeadingContainer = tw.div`text-center `;
const Subheading = tw(SubheadingBase)`mb-4 text-primary-500`;
const Heading = tw(SectionHeading)`w-full text-center`;
const Description = tw(SectionDescription)`mx-auto`;

const Posts = tw.div`mt-12 flex flex-wrap -mr-3 relative`;
const Post = tw.a`flex flex-col h-full bg-gray-200 rounded`;
const PostImage = styled.div`
    ${(props) =>
        css`
            background-image: url('${props.imageSrc}');
        `}
    ${tw`h-64 sm:h-80 bg-center bg-cover rounded-t hover:translate-y-6 transition-colors duration-300 `}
`;
const Container = styled.div`
    ${tw`relative -mx-8 -mt-8 pt-8 sm:pt-8 px-8 h-full bg-white  `}
`;
const PostText = tw.div`flex-1 px-6 py-8`;
const PostTitle = tw.h6`font-bold group-hocus:text-primary-500 transition duration-300 `;
const PostDescription = tw.p``;
const AuthorInfo = tw.div`flex`;
const AuthorImage = tw.img`w-12 h-12 rounded-full mr-3`;
const AuthorTextInfo = tw.div`text-xs text-gray-600`;
const AuthorName = tw.div`font-semibold mt-2`;
const AuthorProfile = tw.div`pt-1 font-medium`;

const PostContainer = styled.div`
    ${tw`relative z-20 mt-10 sm:pt-3 pr-3 w-full  sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

    ${(props) =>
        props.featured &&
        css`
            ${tw`w-full sm:w-full lg:w-2/3`}
            ${Post} {
                ${tw`sm:flex-row items-center sm:pr-3`}
            }
            ${PostImage} {
                ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2 rounded-t sm:rounded-t-none sm:rounded-l`}
            }
            ${PostText} {
                ${tw`pl-8 pr-5`}
            }
            ${PostTitle} {
                ${tw`text-2xl`}
            }
            ${PostDescription} {
                ${tw`mt-4 text-sm font-semibold text-gray-600 leading-relaxed`}
            }
            ${AuthorInfo} {
                ${tw`mt-8 flex items-center`}
            }
            ${AuthorName} {
                ${tw`mt-0 font-bold text-gray-700 text-sm`}
            }
        `}
`;

const DecoratorBlob1 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 w-32 h-32 mb-3 ml-3 transform -translate-x-1/2 translate-y-1/2 fill-current text-gray-500 opacity-50`;
const DecoratorBlob2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 w-32 h-32 mt-16 mr-6 transform translate-x-1/2 -translate-y-1/2 fill-current text-gray-500 opacity-50`;

export default ({
    subheading = 'Our Offering',
    heading = 'Products and Technologies',
    description = '',
    posts = [
        {
            postImageSrc: vPoster,
            title: 'Migr8 Viewform',
            description:
                'Our proprietory Digitial Twin viewer built on web technologies. The generation and manipulation of a Digital Twin is achieved instantly on site and can be viewed, shared and explored using Viewform on any device or computer.',

            url: './',
            featured: true,
        },
        {
            postImageSrc: Poster,
            title: 'Migr8 Capture',
            authorName:
                'Our intuitive capture application that interfaces with a number of camera types. Including the Theta range of 360 cameras and the Leica BLK360',
            url: './',
        },
        {
            postImageSrc: mlPoster,
            title: 'Migr8 Vathos (AI & dataset)',
            authorName:
                'Machine learning forms the foundation of Migr8. Over the last 12 months, we have created a data set of 100,000 synthetic images of plant rooms. Our propriatary model generates depth and segmentation of assets',
            url: './',
        },
        {
            postImageSrc: pcaPoster,
            title: 'Migr8 Blundergat',
            authorName:
                'Blundergat is comprised of a number of modules that allow for point cloud manipulation and model generation. It is used for 3D point cloud registration/alignment, filtering and meshing (including decimation). ',
            url: './',
        },
        {
            postImageSrc: fdPoster,
            title: 'Migr8 Aether',
            authorName:
                'Aether is comprised of a set of state-of-the-art Structure Form Motion (SFM) and feature detection algorithms. These are used across the platform to accurately and efficiently extract relative camera pose information from RGB imagery.',
            url: './',
        },
    ],
}) => {
    return (
        <Container>
            <ContentWithPaddingXl>
                <HeadingContainer>
                    {subheading && <Subheading>{subheading}</Subheading>}
                    {heading && <Heading>{heading}</Heading>}
                    {description && <Description>{description}</Description>}
                </HeadingContainer>
                <Posts>
                    {posts.map((post, index) => (
                        <PostContainer featured={post.featured} key={index}>
                            <Post className="group" href={post.url}>
                                <PostImage imageSrc={post.postImageSrc} />
                                <PostText>
                                    <PostTitle>{post.title}</PostTitle>
                                    {post.featured && (
                                        <PostDescription>
                                            {post.description}
                                        </PostDescription>
                                    )}
                                    <AuthorInfo>
                                        {post.featured && (
                                            <AuthorImage
                                                src={post.authorImageSrc}
                                            />
                                        )}
                                        <AuthorTextInfo>
                                            <AuthorName>
                                                {post.authorName}
                                            </AuthorName>
                                            {post.featured && (
                                                <AuthorProfile>
                                                    {post.authorProfile}
                                                </AuthorProfile>
                                            )}
                                        </AuthorTextInfo>
                                    </AuthorInfo>
                                </PostText>
                            </Post>
                        </PostContainer>
                    ))}
                    <DecoratorBlob1 />
                    <DecoratorBlob2 />
                </Posts>
            </ContentWithPaddingXl>
        </Container>
    );
};
