import React from 'react';
import styled from 'styled-components'; //eslint-disable-line
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-9.svg';
import { ContentWithPaddingXl } from 'components/misc/Layouts';

const PrimaryBackgroundContainer = tw.div`mx-8 py-20 h-128 sm:h-96 lg:py-24 bg-gray-900  rounded-lg relative`;
const Row = tw.div`px-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;
const Block = tw.div`px-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;
const Container = styled.div`
    ${tw`relative  -mx-8  bg-gray-200   `}
`;
const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`;
const TextContainer = tw(ColumnContainer)``;
const Text = tw.h5`text-gray-100 text-2xl sm:text-3xl font-bold`;

const LinksContainer = tw(
    ColumnContainer
)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
    Link
)`bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200`;

const SecondaryLink = tw(
    Link
)`text-gray-100 border-gray-500 hover:bg-gray-100 hover:text-primary-500 hover:border-primary-500`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
    SvgDecoratorBlob1
)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-900 opacity-50`;
const DecoratorBlob2 = tw(
    SvgDecoratorBlob1
)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-primary-500 opacity-50`;

const testimonials = [
    {
        heading: 'UK Tech',
        quote: 'Our digital twin tours can integrate with existing digital twin models to provide a real world layer of functionality to complement purely digital / data twin models generated within software such as Willow',
    },
    {
        heading: 'Security',
        quote: 'Migr8 is additive and complimentary to those digital twins, bringing them life in a visually accurate way which provides many valuable user cases.',
    },
];
export default ({
    text = 'Our Digital Twin tours can integrate with existing digital twin models to provide a real world layer of functionality to complement purely digital / data twin models generated within software such as Willow',
    primaryLinkText = 'Get Started',
    primaryLinkUrl = 'http://timerse.com',
    secondaryLinkText = 'Contact Us',
    secondaryLinkUrl = 'http://google.com',
    pushDownFooter = true,
}) => {
    const [newName, setnewName] = React.useState({
        heading: 'Practical',
        quote: 'Migr8 is additive and complimentary to those digital twins, bringing them life in a visually accurate way which provides many valuable user cases.',
    });

    const shuffle = React.useCallback(() => {
        const index = Math.floor(Math.random() * testimonials.length);

        setnewName(testimonials[index]);
    }, []);

    React.useEffect(() => {
        const intervalID = setInterval(shuffle, 7000);
        return () => clearInterval(intervalID);
    }, [shuffle]);

    return (
        <Container>
            <ContentWithPaddingXl>
                <PrimaryBackgroundContainer>
                    <Block>
                        <Text>{newName.quote}</Text>
                    </Block>

                    <DecoratorBlobContainer>
                        <DecoratorBlob1 />
                        <DecoratorBlob2 />
                    </DecoratorBlobContainer>
                </PrimaryBackgroundContainer>
            </ContentWithPaddingXl>
        </Container>
    );
};
