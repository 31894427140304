import React from 'react';
import AnimationRevealPage from 'Migr8/components/helpers/AnimationRevealPage.js';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import tw from 'twin.macro';
import styled from 'styled-components'; //eslint-disable-line
import { css } from 'styled-components/macro'; //eslint-disable-line
import Header from 'Migr8/components/headers/light.js';
import Footer from 'Migr8/components/footer/FiveColumnDark.js';
import MainFeature1 from 'Migr8/components/features/section2.js';
import { ContentWithVerticalPadding } from 'components/misc/Layouts';
import {
    SectionHeading,
    Subheading as SubheadingBase,
} from 'components/misc/Headings.js';

import ReactModalAdapter from '../helpers/ReactModalAdapter.js';
import ResponsiveVideoEmbed from './components/helpers/ResponsiveVideoEmbed.js';

import { ReactComponent as PlayIcon } from 'feather-icons/dist/icons/play-circle.svg';
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-1.svg';
import { ReactComponent as SvgDecoratorBlob2 } from 'images/dot-pattern.svg';
import DesignIllustration from './assets/images/prod.png';
import Viewform from './assets/gifs/viewflong.gif';
import Capture from './assets/gifs/captureapp.gif';
import Temple from './assets/images/temple.png';

const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;
const Heading = tw(SectionHeading)`w-full `;
const Subheading = tw.span`uppercase tracking-wider text-sm text-primary-500`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const ActionButton = tw(
    AnchorLink
)`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const PrimaryButton = tw(ActionButton)``;

const WatchVideoButton = styled.button`
    ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
    .playIcon {
        ${tw`stroke-1 w-12 h-12`}
    }
    .playText {
        ${tw`ml-2 font-medium`}
    }
`;
const SectionContainer = tw(ContentWithVerticalPadding)``;
const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
    ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
    ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const StyledModal = styled(ReactModalAdapter)`
    &.mainHeroModal__overlay {
        ${tw`fixed inset-0 z-50`}
    }
    &.mainHeroModal__content {
        ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
    }
    .content {
        ${tw`w-full lg:p-16`}
    }
`;

const Container = styled.div`
    ${tw`relative -mx-8 -mt-8 pt-8 px-8 bg-center bg-gray-200 bg-cover h-screen min-h-144`}
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default ({
    heading = 'Migr8 Products',
    description = "The business of Digital Twin creation is complex, and automating this process only adds to the complexity, so technology is at the core of what we do. To facilitate our automation pipeline a number of cutting edge technologies for Machine Learning (ML) and Computer Vision (CV) have been developed. Below you will find Migr8's three flagship products.",
    primaryButtonText = 'Learn more',
    primaryButtonUrl = '#viewform',
    watchVideoButtonText = 'Watch Video',
    watchVideoYoutubeUrl = 'https://www.youtube.com/embed/_GuOjXYl5ew',
    imageSrc = DesignIllustration,
    imageCss = null,
    imageDecoratorBlob = false,
}) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);

    const toggleModal = () => setModalIsOpen(!modalIsOpen);
    return (
        <AnimationRevealPage disabled>
            <Container>
                <Header />
                <TwoColumn>
                    <LeftColumn>
                        <Heading>{heading}</Heading>
                        <Paragraph>{description}</Paragraph>
                        <Actions>
                            <PrimaryButton as="a" href={primaryButtonUrl}>
                                {primaryButtonText}
                            </PrimaryButton>
                            {
                                !(
                                    <WatchVideoButton onClick={toggleModal}>
                                        <span className="playIconContainer">
                                            <PlayIcon className="playIcon" />
                                        </span>
                                        <span className="playText">
                                            {watchVideoButtonText}
                                        </span>
                                    </WatchVideoButton>
                                )
                            }
                        </Actions>
                    </LeftColumn>
                    <RightColumn>
                        <IllustrationContainer>
                            <img css={imageCss} src={imageSrc} alt="Hero" />
                            {imageDecoratorBlob && <DecoratorBlob2 />}
                        </IllustrationContainer>
                    </RightColumn>
                </TwoColumn>
                <DecoratorBlob1 />
                <StyledModal
                    closeTimeoutMS={300}
                    className="mainHeroModal"
                    isOpen={modalIsOpen}
                    onRequestClose={toggleModal}
                    shouldCloseOnOverlayClick={true}
                >
                    <CloseModalButton onClick={toggleModal}>
                        <CloseIcon tw="w-6 h-6" />
                    </CloseModalButton>
                    <div className="content">
                        <ResponsiveVideoEmbed
                            url={watchVideoYoutubeUrl}
                            tw="w-full"
                        />
                    </div>
                </StyledModal>
            </Container>
            <SectionContainer id="viewform">
                <MainFeature1
                    bg="#f7fafc"
                    subheading={<Subheading>Virtual tours</Subheading>}
                    heading="Migr8 Viewform"
                    buttonRounded={false}
                    primaryButtonUrl={'./viewform'}
                    description="Viewform is our flagship product for viewing and navigating digital twins. This streamlined 3D viewer works within the browser and on mobile devices allowing you to quickly “walk” through your space. Intuitive controls allow you to explore a 3D walkthrough of your space, take measurements and add labels dynamically within the browser. Asset tagging directly after capture can save hours and save repeat visits to a location. Through the extensive API, live data can be streamed into the tour to give compelling visual insights into your digital twin.
                    "
                    primaryButtonText="Read more"
                    imageSrc={Viewform}
                    textOnLeft={false}
                />
            </SectionContainer>
            <SectionContainer id="capture">
                <MainFeature1
                    bg="#edf2f7"
                    subheading={<Subheading>Capture application</Subheading>}
                    heading="Migr8 Capture"
                    buttonRounded={false}
                    description="Migr8 Capture gives you the ability to automatically create a Digital Twin by pairing the ios/android app with a supported camera. The Migr8 Mapping app is simplifies the process of creating digital twins by giving feedback as you scan your space. Once complete, the tour is processed securely on the Azure cloud and made available instantly for on-site asset tagging. See our camera/feature matrix for comparisons."
                    primaryButtonText="Read more"
                    primaryButtonUrl={'./scannerApp'}
                    imageSrc={Capture}
                />
            </SectionContainer>
            <SectionContainer id="temple">
                <MainFeature1
                    bg="#f7fafc"
                    subheading={
                        <Subheading>
                            Dynamic Cloud and On-premises Deployment
                        </Subheading>
                    }
                    heading="Migr8 Temple"
                    description="When security and confidentiality are paramount to your organization, you can choose Migr8 Temple. We support on-premises Microsoft Azure deployment allowing you custody of your data, safe in the knowledge it is “for your eyes only”."
                    buttonRounded={false}
                    primaryButtonText="Read more"
                    imageSrc={Temple}
                    textOnLeft={false}
                />
            </SectionContainer>
            <Footer />
        </AnimationRevealPage>
    );
};
