import React, { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import {
    SectionHeading,
    Subheading as SubheadingBase,
} from '../../../components/misc/Headings.js';
import { PrimaryButton } from '../../../components/misc/Buttons.js';

import { ReactComponent as ChevronLeftIcon } from 'feather-icons/dist/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'feather-icons/dist/icons/chevron-right.svg';
import { ReactComponent as IntIcon } from 'feather-icons/dist/icons/link.svg';
import 'slick-carousel/slick/slick.css';
import CrimeImg from './../../assets/images/crime.jpeg';
import BelfastImg from './../../assets/images/belfast.jpg';
import ManageImg from './../../assets/images/manage.jpg';
const Container = tw.div`relative bg-gray-900 -mb-8 -mx-8 px-8 py-0 lg:py-0`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const TestimonialsContainer = tw.div`mt-16 lg:mt-0`;
const Testimonials = styled.div``;
const Testimonial = tw.div`max-w-xl lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)((props) => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft
        ? tw`md:mr-12 lg:mr-16 md:order-first`
        : tw`md:ml-12 lg:ml-16 md:order-last`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const TestimonialImageSlider = tw(Slider)`  w-full lg:w-5/12 flex-shrink-0 `;
const TestimonialTextSlider = tw(Slider)``;
const TestimonialText = tw.div`outline-none`;
const Heading = tw(
    SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Features = tw.div`mt-8 max-w-lg mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
    ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
    svg {
        ${tw`w-5 h-5 text-primary-500`}
    }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-gray-300`;
const FeatureDescription = tw.div`mt-1 text-sm text-gray-500`;

const ImageAndControlContainer = tw.div`relative outline-none  `;
const Image = styled.div((props) => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-cover bg-center h-64 lg:h-144  `,
]);

const ControlContainer = tw.div`absolute bottom-0 right-0 bg-gray-100 px-6 py-4 mb-4 mr-4 rounded-xl border `;
const ControlButton = styled(PrimaryButton)`
    ${tw`mx-3 rounded-full text-gray-100 p-2`}
    svg {
        ${tw`w-5 h-5`}
    }
`;

const TextContainer = styled.div((props) => [
    tw`flex flex-col w-full lg:w-7/12`,
    props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`,
]);

const Subheading = tw(SubheadingBase)`mb-4 mt-0 text-center text-primary-500`;
const HeadingTitle = tw(
    SectionHeading
)`mt-8 text-center lg:text-left text-gray-200 leading-tight`;
const Description = tw.p`max-w-md text-left sm:text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-gray-400`;

const QuoteContainer = tw.div`relative mt-10 mb-20 lg:mt-20`;
const Quote = tw.blockquote`text-center text-gray-500 lg:text-left text-sm `;
const CustomerInfo = tw.div`mt-6 flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-20 h-20`;
const CustomerTextInfo = tw.div`text-center lg:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-semibold text-xl mb-4 lg:text-2xl xl:text-3xl text-gray-200`;
const CustomerTitle = tw.p`font-medium text-secondary-100`;

export default ({
    features = null,
    subheading = 'Industries',
    heading = 'User cases',
    description = 'Here are what some of our amazing customers are saying about our hotels & tours. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    testimonials = null,
    textOnLeft = false,
}) => {
    /*
     * You can modify the testimonials shown by modifying the array below or passing in the testimonials prop above
     * You can add or remove objects from the array as you need.
     */
    const defaultFeatures = [
        [
            {
                Icon: IntIcon,
                title: 'Inspection',
                description:
                    'Allowing initial visual inspection and assessment of any issue to be carried out by local employees, guided in real time by a remotely located engineer, reducing both cost and time for response',
            },
            {
                Icon: IntIcon,
                title: 'Measurements',
                description:
                    'Providing accurate measurements / dimensions required for asset / equipment upgrades / replacements without needing visit site.',
            },
            {
                Icon: IntIcon,
                title: 'Asset Inventory',
                description:
                    'Providing a comprehensive and quick to deploy asset tagging and barcode recording system to manage asset inventory.',
            },
            {
                Icon: IntIcon,
                title: 'Floor Plans',
                description:
                    'Providing accurate floor plans with measurement tools and great overview of the space',
            },
            {
                Icon: IntIcon,
                title: 'Added content',
                description:
                    'Allowing asset maintenance manuals and training content to be made readily available at point of visual inspection',
            },
        ],

        [
            {
                Icon: IntIcon,
                title: 'Guided',
                description:
                    'Allowing initial visual inspection and assessment of any issue to be carried out by local service personal, guided in real time by a remotely located engineer, reducing both cost and time for response.',
            },
            {
                Icon: IntIcon,
                title: 'Available',
                description:
                    'Allows assessment and diagnosis without the need for offshore assets such as naval ships to return to dock.',
            },
            {
                Icon: IntIcon,
                title: 'Measurements',
                description:
                    'Providing accurate measurements / dimensions required for asset / equipment upgrades / replacements without needing visit site.',
            },
            {
                Icon: IntIcon,
                title: 'Ease of use',
                description:
                    'Providing a comprehensive and quick to deploy asset tagging and recording system to manage asset inventory.',
            },
            {
                Icon: IntIcon,
                title: 'Added content',
                description:
                    'Allowing asset maintenance manuals and training content to be made readily available at point of visual inspection.',
            },
        ],

        [
            {
                Icon: IntIcon,
                title: 'Guided',
                description:
                    'Allowing initial visual inspection and assessment of any issue to be carried out by local employees, guided in real time by a remotely located engineer, reduce both cost and time for response.',
            },

            {
                Icon: IntIcon,
                title: 'Measurements',
                description:
                    'Providing accurate measurements / dimensions required for asset / equipment upgrades / replacements without needing visit site.',
            },
            {
                Icon: IntIcon,
                title: 'Ease of use',
                description:
                    'Providing a comprehensive and quick to deploy asset tagging and recording system to manage asset inventory.',
            },
            {
                Icon: IntIcon,
                title: 'Floor Plans',
                description:
                    'Providing accurate floor plans with measurement tools and great overview of the space',
            },
            {
                Icon: IntIcon,
                title: 'Added content',
                description:
                    'Allowing asset maintenance manuals and training content to be made readily available at point of visual inspection.',
            },
        ],
    ];

    if (!features) features = defaultFeatures;

    const defaultTestimonials = [
        {
            imageSrc: ManageImg,
            icon: IntIcon,
            ind: 'Facilities Management',
            head: 'Migr8 Digital Twins drive significant cost savings and efficiencies for facilities management providers by :',
            profileImageSrc:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80',
            quote: 'Allowing initial visual inspection and assessment of any issue to be carried out by local employees, guided in real time by a remotely located engineer, reducing both cost and time for response.',
            p1: '-Providing accurate measurements / dimensions required for asset / equipment upgrades / replacements without needing visit site.',
            p2: '-Providing a comprehensive and quick to deploy asset tagging and  barcode recording system to manage asset inventory.',
            p3: '-Providing accurate floor plans.',
            p4: '-Allowing asset maintenance manuals and training content to be made readily available at point of visual inspection.',
            p5: 'customerName: "Building Asset Management',
            p6: 'customerTitle: CEO, Delos Inc.',
        },
        {
            imageSrc: BelfastImg,
            icon: BelfastImg,
            ind: 'Defence sector',
            head: 'Mig8 digital twins have been developed by working in conjunction with national several service providers to the UK MOD to ensure that data protocols comply with the highest security requirements. Migr8’s digital twins create significant value for the defence sector by :',
            profileImageSrc:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80',
            quote: 'dimensionally accurate crime scene mapping that can allow all data to be tagged to the specific areas, e.g. ballistics, DNA, etc.',
            customerName: 'Policing',
            customerTitle: 'CEO, Delos Inc.',
        },
        {
            imageSrc: CrimeImg,
            icon: IntIcon,
            ind: 'Local Government',
            head: 'Mig8 digital twins drive cost significant cost savings for local government sectors by :',
            profileImageSrc:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80',
            quote: 'dimensionally accurate crime scene mapping that can allow all data to be tagged to the specific areas, e.g. ballistics, DNA, etc.',
            customerName: 'Policing',
            customerTitle: 'CEO, Delos Inc.',
        },
    ];

    if (!testimonials || testimonials.length === 0)
        testimonials = defaultTestimonials;

    // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
    const [imageSliderRef, setImageSliderRef] = useState(null);
    const [textSliderRef, setTextSliderRef] = useState(null);
    const [newName, setnewName] = React.useState({
        heading: 'Practical',
        quote: 'Migr8 is additive and complimentary to those digital twins, bringing them life in a visually accurate way which provides many valuable user cases.',
    });

    const shuffle = React.useCallback(() => {
        const index = Math.floor(Math.random() * testimonials.length);
        // console.log(imageSliderRef + '' + index);

        //setImageSliderRef(slickNext);
        //setImageSliderRef(true);
    }, []);

    React.useEffect(() => {
        const intervalID = setInterval(shuffle, 700);

        return () => clearInterval(intervalID);
    }, [shuffle]);

    return (
        <Container>
            <Content>
                {subheading ? <Subheading>{subheading}</Subheading> : null}
                <TestimonialsContainer>
                    <Testimonials>
                        <Testimonial>
                            <TestimonialImageSlider
                                autoplaySpeed={16000}
                                autoplay={true}
                                arrows={false}
                                ref={setImageSliderRef}
                                asNavFor={textSliderRef}
                                fade={true}
                            >
                                {testimonials.map((testimonial, index) => (
                                    <ImageAndControlContainer
                                        autoplay={true}
                                        key={index}
                                    >
                                        <Image
                                            imageSrc={testimonial.imageSrc}
                                        />
                                        <ControlContainer>
                                            <ControlButton
                                                onClick={
                                                    imageSliderRef?.slickPrev
                                                }
                                            >
                                                <ChevronLeftIcon />
                                            </ControlButton>
                                            <ControlButton
                                                onClick={
                                                    imageSliderRef?.slickNext
                                                }
                                            >
                                                <ChevronRightIcon />
                                            </ControlButton>
                                        </ControlContainer>
                                    </ImageAndControlContainer>
                                ))}
                            </TestimonialImageSlider>

                            <TextContainer textOnLeft={textOnLeft}>
                                <TestimonialTextSlider
                                    arrows={false}
                                    ref={setTextSliderRef}
                                    asNavFor={imageSliderRef}
                                    fade={true}
                                >
                                    {testimonials.map((testimonial, index) => (
                                        <TestimonialText key={index}>
                                            <HeadingInfo
                                                tw=" lg:block"
                                                subheading={subheading}
                                                heading={testimonial.ind}
                                                description={testimonial.head}
                                            />

                                            <QuoteContainer>
                                                <Features>
                                                    {features[index].map(
                                                        (feature, index) => (
                                                            <Feature
                                                                key={index}
                                                            >
                                                                <FeatureIconContainer>
                                                                    {
                                                                        <feature.Icon />
                                                                    }
                                                                </FeatureIconContainer>
                                                                <FeatureText>
                                                                    <FeatureHeading>
                                                                        {
                                                                            feature.title
                                                                        }
                                                                    </FeatureHeading>
                                                                    <FeatureDescription>
                                                                        {
                                                                            feature.description
                                                                        }
                                                                    </FeatureDescription>
                                                                </FeatureText>
                                                            </Feature>
                                                        )
                                                    )}
                                                </Features>
                                            </QuoteContainer>
                                        </TestimonialText>
                                    ))}
                                </TestimonialTextSlider>
                            </TextContainer>
                        </Testimonial>
                    </Testimonials>
                </TestimonialsContainer>
            </Content>
        </Container>
    );
};

const HeadingInfo = ({ subheading, heading, description, ...props }) => (
    <div {...props}>
        <HeadingTitle>{heading}</HeadingTitle>
        <Description>{description}</Description>
    </div>
);
