import React from 'react';
import AnimationRevealPage from 'Migr8/components/helpers/AnimationRevealPage.js';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import tw from 'twin.macro';
import styled from 'styled-components'; //eslint-disable-line
import { css } from 'styled-components/macro'; //eslint-disable-line
import Header from 'Migr8/components/headers/light.js';
import Footer from 'Migr8/components/footer/FiveColumnDark.js';
import MainFeature1 from 'Migr8/components/features/section2.js';
import { ContentWithVerticalPadding } from 'components/misc/Layouts';
import {
    SectionHeading,
    Subheading as SubheadingBase,
} from 'components/misc/Headings.js';
import MainFeature3 from 'components/features/TwoColWithTwoHorizontalFeaturesAndButton';

import { ReactComponent as BriefcaseIcon } from 'feather-icons/dist/icons/copy.svg';
import { ReactComponent as MoneyIcon } from 'feather-icons/dist/icons/columns.svg';
import ReactModalAdapter from '../helpers/ReactModalAdapter.js';
import ResponsiveVideoEmbed from './components/helpers/ResponsiveVideoEmbed.js';

import { ReactComponent as PlayIcon } from 'feather-icons/dist/icons/play-circle.svg';
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-1.svg';
import { ReactComponent as SvgDecoratorBlob2 } from 'images/dot-pattern.svg';
import DesignIllustration from './assets/images/techim.png';
import Blunder from './assets/images/Aether.jpg';
import Vathos from './assets/images/dataviz.jpg';
import Acid from './assets/images/Aether_2.jpg';
import Slam from './assets/gifs/slam.gif';

const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;
const Heading = tw(SectionHeading)`w-full `;
const Subheading = tw.span`uppercase tracking-wider text-sm text-primary-500`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const ActionButton = tw(
    AnchorLink
)`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const PrimaryButton = tw(ActionButton)``;
//const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const WatchVideoButton = styled.button`
    ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
    .playIcon {
        ${tw`stroke-1 w-12 h-12`}
    }
    .playText {
        ${tw`ml-2 font-medium`}
    }
`;
const SectionContainer = tw(ContentWithVerticalPadding)``;
const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
    ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
    ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const StyledModal = styled(ReactModalAdapter)`
    &.mainHeroModal__overlay {
        ${tw`fixed inset-0 z-50`}
    }
    &.mainHeroModal__content {
        ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
    }
    .content {
        ${tw`w-full lg:p-16`}
    }
`;

const Container = styled.div`
    ${tw`relative -mx-8 -mt-8 pt-8 px-8 bg-center bg-gray-200 bg-cover h-screen min-h-144`}
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;
const myfet = [
    {
        Icon: BriefcaseIcon,
        title: 'Point Cloud Registration',
        description:
            'Blundergat uses a state-of-the-art hardware accelerated Particle-Swarm-Optimization algorithm, to rapidly register/align even the largest of point clouds. ',
        iconContainerCss: tw`bg-teal-300 text-teal-800`,
    },
    {
        Icon: MoneyIcon,
        title: 'Mesh Creation and Decimation',
        description:
            'Blundergat also provides automated meshing and decimation, again using the very latest techniques and algorithms to ensure we are providing clients with the best possible experience.',
        iconContainerCss: tw`bg-red-300 text-red-800`,
    },
];
export default ({
    heading = 'Migr8 Technologies',
    description = "An organization's physical workplace can account for a huge portion of its operating costs. Migr8 aims to bridge the gap between people and place by improving the connections between them. We are passionate, innovative technologists focused on leading research and development in the field of Digital Twins. Our cutting edge technologies help you understand the complex environments you operate within.",
    primaryButtonText = 'Learn more',
    primaryButtonUrl = '#landingPageDemos',
    watchVideoButtonText = 'Watch Video',
    watchVideoYoutubeUrl = 'https://www.youtube.com/embed/_GuOjXYl5ew',
    imageSrc = DesignIllustration,
    imageCss = null,
    imageDecoratorBlob = false,
}) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);

    const toggleModal = () => setModalIsOpen(!modalIsOpen);
    return (
        <AnimationRevealPage disabled>
            <Container>
                <Header light={false} />
                <TwoColumn>
                    <LeftColumn>
                        <Heading>{heading}</Heading>
                        <Paragraph>{description}</Paragraph>
                        <Actions>
                            <PrimaryButton as="a" href={primaryButtonUrl}>
                                {primaryButtonText}
                            </PrimaryButton>
                            {
                                !(
                                    <WatchVideoButton onClick={toggleModal}>
                                        <span className="playIconContainer">
                                            <PlayIcon className="playIcon" />
                                        </span>
                                        <span className="playText">
                                            {watchVideoButtonText}
                                        </span>
                                    </WatchVideoButton>
                                )
                            }
                        </Actions>
                    </LeftColumn>
                    <RightColumn>
                        <IllustrationContainer>
                            <img css={imageCss} src={imageSrc} alt="Hero" />
                            {imageDecoratorBlob && <DecoratorBlob2 />}
                        </IllustrationContainer>
                    </RightColumn>
                </TwoColumn>
                <DecoratorBlob1 />
                <StyledModal
                    closeTimeoutMS={300}
                    className="mainHeroModal"
                    isOpen={modalIsOpen}
                    onRequestClose={toggleModal}
                    shouldCloseOnOverlayClick={true}
                >
                    <CloseModalButton onClick={toggleModal}>
                        <CloseIcon tw="w-6 h-6" />
                    </CloseModalButton>
                    <div className="content">
                        <ResponsiveVideoEmbed
                            url={watchVideoYoutubeUrl}
                            tw="w-full"
                        />
                    </div>
                </StyledModal>
            </Container>
            <SectionContainer id="landingPageDemos">
                <MainFeature1
                    bg="#f7fafc"
                    subheading={<Subheading>Machine Learning </Subheading>}
                    heading="Migr8 Vathos"
                    buttonRounded={false}
                    primaryButtonUrl={'./viewform'}
                    description="Vathos is a new fusion-based machine learning model that builds on top of existing research using state of the art Machine Learning for proscessing spherical panoramic images. Our model “fuses” features from both equirectangular and cube map projections together, while constantly adding new aspects to the training model. At Migr8, we have created a vast proprietary training dataset enabling us to become a true leader in computer vision."
                    primaryButtonText="Read more"
                    imageSrc={Vathos}
                    textOnLeft={false}
                />
            </SectionContainer>
            <MainFeature1
                bg="#edf2f7"
                subheading={<Subheading>Computer Vision</Subheading>}
                heading="Migr8 Aether"
                buttonRounded={false}
                description="Aether uses state-of-the-art hardware accelerated SFM solvers, to automatically extract and apply relative pose information during the capture process. This streamlines tour creation, vastly improves pose accuracy and tour quality and provides a seamless user experience."
                primaryButtonText="Read more"
                primaryButtonUrl={'./scannerApp'}
                imageSrc={Acid}
            />
            <MainFeature3
                bg="#f7fafc"
                id="ML"
                subheading={<Subheading>Pointcloud manipulation</Subheading>}
                heading="Migr8 Blundergat"
                buttonRounded={false}
                features={myfet}
                description="A bit of a heavyweight, Blundergat is Migr8’s point cloud utility. Blundergat provides us with the ability to manipulate point clouds in anyway necessary and boasts the following features:"
                primaryButtonText="Read more"
                imageSrc={Blunder}
                textOnLeft={false}
            />
            <MainFeature1
                bg="#edf2f7"
                subheading={<Subheading>Video tour creation</Subheading>}
                heading="Migr8 SLAM"
                description="Migr8’s Simultaneous Localization and Mapping (SLAM) capability, provides the user with the ability to create digital twins using the Migr8 app directly via video capture. 
                Migr8 SLAM can also support input from a number of different devices and is agnostic to image format. Hardware accelerated submodules make Migr8 SLAM a viable option, even when running on mobile hardware. 
                "
                buttonRounded={false}
                primaryButtonText="Read more"
                imageSrc={Slam}
            />

            <Footer />
        </AnimationRevealPage>
    );
};
