import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import AnimationRevealPage from 'Migr8/components/helpers/AnimationRevealPage.js';
import Header, {
    NavLink,
    NavLinks,
    PrimaryLink as PrimaryLinkBase,
    LogoLink,
    NavToggle,
    DesktopNavLinks,
} from '../headers/light.js';
import ResponsiveVideoEmbed from '../helpers/ResponsiveVideoEmbed';

import { ReactComponent as QuotesLeftIcon } from '../../../images/quotes-l.svg';
import { ReactComponent as QuotesRightIcon } from '../../../images/quotes-r.svg';
import {
    Container as ContainerBase,
    ContentWithVerticalPadding,
    Content2Xl,
} from 'components/misc/Layouts.js';
import { SectionHeading, Subheading } from 'components/misc/Headings.js';
import { SectionDescription } from 'components/misc/Typography.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import logoImageSrc from 'images/logo-light.svg';
import serverIllustrationImageSrc from './../../assets/videos/clip.mp4';
import Poster from './../../assets/images/tom.jpg';
import { ReactComponent as CheckboxIcon } from 'feather-icons/dist/icons/check-circle.svg';
const StyledHeader = styled(Header)`
    ${tw`-mt-16 sm:-mt-8 pt-0 max-w-none  w-full `}
    ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
        ${tw` text-gray-100 hover:border-gray-600 hover:text-white`}
    }
    ${NavToggle}.closed {
        ${tw`  text-gray-100 hover:text-primary-500`}
    }
`;
const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-secondary-900 text-gray-100`;

const PrimaryLink = tw(
    PrimaryLinkBase
)`shadow-raised lg:bg-primary-400 lg:hocus:bg-primary-500`;

const Row = tw.div` flex items-center flex-col lg:flex-row py-12 sm:py-8 xl:py-32 `;
const Column = tw.div`lg:w-1/6`;
const TextColumn = tw.div`text-justify md:text-left`;
const IllustrationColumn = tw(Column)`mt-16 lg:mt-0 lg:ml-16`;
const Heading = tw(
    SectionHeading
)` max-w-3xl bg-opacity-10  text-gray-200 lg:max-w-4xl `;
const Description = tw(
    SectionDescription
)`mt-4 max-w-2xl text-gray-300 lg:text-base mx-auto lg:mx-0`;
const PrimaryButton = styled(PrimaryButtonBase).attrs({ as: 'a' })`
    ${tw`inline-block mt-4 text-sm font-semibold`}
`;
const TwoColumn = tw.div`pt-12 sm:pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;
//const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm  mx-auto `;
const Image = tw.img`w-144 ml-auto`;

const Container = styled.div`
    ${tw`relative -mx-8 -mt-8 pt-8 px-8 h-screen   `}
    background-image: url("${serverIllustrationImageSrc}");
`;
const OpacityOverlay = tw.div`bg-gradient-to-r from-gray-900  to-blue-900 opacity-75 z-10 absolute inset-0  `;
const HeroContainer = tw.div` z-20 relative px-0 h-1/2 -mx-8 py-16 max-w-screen-xl mx-auto`;
const HeroContainer2 = tw.div`  z-20 relative px-0 h-1/4 -mx-8 py-16 max-w-screen-xl mx-auto`;

const Vid = tw.video`z-0 fixed  object-cover lg:w-screen w-auto h-full  -mx-8 -mt-8 pt-0 px-0     `;
const FeatureList = tw.ul`mt-6 leading-loose flex flex-wrap max-w-xl mx-auto lg:mx-0`;
const Feature = tw.li`mt-1 flex items-center flex-shrink-0 w-full w-1/2 justify-start`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-gray-100`;
const FeatureText = tw.p`ml-2 font-medium text-gray-200`;
const Testimonial = tw.div`outline-none h-full flex! flex-col`;
const TestimonialHeading = tw.div`mt-4 text-xl font-bold text-primary-900 text-center  lg:text-left`;
const QuoteContainer = tw.div`  relative py-0 lg:mt-0`;
const Quote = tw(
    SectionHeading
)`text-lg sm:text-3xl opacity-100 max-w-4xl text-gray-400 text-center lg:text-left font-bold   `;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
    padding-bottom: 56.25% !important;
    padding-top: 0px !important;
    ${tw`rounded`}
    iframe {
        ${tw`rounded bg-black shadow-xl`}
    }
`;
const QuotesLeft = tw(
    QuotesLeftIcon
)`w-6 h-6 opacity-75 text-gray-100 inline-block mr-1 -mt-3`;
const QuotesRight = tw(
    QuotesRightIcon
)`w-6 h-6 opacity-75 text-gray-100 inline-block ml-1 -mt-3`;

const testimonials = [
    {
        heading: 'UK Tech',
        quote: '100% home grown and owned UK technology business.',
    },
    {
        heading: 'Security',
        quote: 'Built with the defence sector in mind.',
    },
    {
        heading: 'Azure',
        quote: 'Built for Azure digital twin clients to optimise their effectiveness and productivity.',
    },
    {
        heading: 'Simplifies',
        quote: 'Breaks down the barriers between data technologists and everyday users.',
    },
    {
        heading: 'Practical',
        quote: 'Transforms complicated and intimidating digital twins into into practical, actionable intuitive tools for everyone.',
    },
    {
        heading: 'Bridging gaps',
        quote: 'Powerful tools to interface between technologists and everyday users of spaces and places.',
    },
];
export default ({
    features = null,
    heading = 'Asset Digital Twins',
    subheading = 'A turnkey real world solution',

    description = (
        <>
            <span tw="text-white" tw="font-semibold">
                Migr8’s
            </span>{' '}
            Asset Digital Twins provide both a turnkey solution and a
            complimentary real world 3D model addition for more complex digital
            twin deployments.
            <br /> <br /> Harnessing the latest advances in scanning and machine
            learning, our digital twins are quick and easy to create, highly
            secure and integrate with all digital twin platform providers.{' '}
            <br />
        </>
    ),
    primaryButtonText = 'Explore products',
    primaryButtonUrl = '/products',
    imageSrc = serverIllustrationImageSrc,
}) => {
    features = features || [
        `Digital twin`,
        `Asset capture`,
        `Live data link`,
        'Works on all devices',
        'Fully Azure compatible',
        'Embeddable',
    ];

    const [newName, setnewName] = React.useState({
        heading: 'Practical',
        quote: 'Transforms complicated and intimidating digital twins into into practical, actionable intuitive tools for everyone.',
    });

    const shuffle = React.useCallback(() => {
        const index = Math.floor(Math.random() * testimonials.length);

        setnewName(testimonials[index]);
    }, []);

    React.useEffect(() => {
        const intervalID = setInterval(shuffle, 5000);
        return () => clearInterval(intervalID);
    }, [shuffle]);

    return (
        <Container>
            <OpacityOverlay />
            <Vid
                autoPlay
                loop
                playsInline
                muted
                poster={Poster}
                src={serverIllustrationImageSrc}
                type="video/mp4"
            ></Vid>
            <HeroContainer>
                <StyledHeader />
                <TwoColumn>
                    <LeftColumn>
                        <TextColumn>
                            <Heading>{heading}</Heading>
                            <Subheading>{subheading}</Subheading>
                            <Description>{description}</Description>
                            <PrimaryButton as="a" href={primaryButtonUrl}>
                                {primaryButtonText}
                            </PrimaryButton>
                        </TextColumn>
                    </LeftColumn>
                    <RightColumn>
                        <StyledResponsiveVideoEmbed
                            url="//player.vimeo.com/video/721027040?fullscreen"
                            background="transparent"
                        />
                    </RightColumn>
                </TwoColumn>
            </HeroContainer>
        </Container>
    );
};
